import PropTypes from 'prop-types';
import styled from 'styled-components';

import Taxi from '../../../images/taxi.jpg';

const InsuranceContract = ({ data }) => {
  return (
    <InsuranceContractContainer>
      <h1>
        Forsikringsavtale {data.policyNumber} {data.fields.registrationNumber}
      </h1>
      <p>
        Vi bekrefter at forsikringsavtale er inngått. I dette dokumentet finner
        du all nødvendig informasjon som vi ber deg lese nøye igjennom. Er noe
        uklart, utelatt eller feil må du melde fra så raskt som mulig.
      </p>
      <p>I tillegg til denne forsikringsavtalen gjelder:</p>
      <UL>
        <li>Forsikringsbeviset</li>
        <li>Generelle vilkår</li>
        <li>Forsikringsvilkårene</li>
        <li>Sikkerhetsforskrifter</li>
        <li>
          Lov om forsikringsavtaler av 16. juni 1989 (FAL), Bilansvarsloven av 3
          februar 1961 (BAL)
        </li>
        <li>Øvrig lovverk</li>
      </UL>
      <ImageContainer>
        <img src={Taxi} alt='Taxi' />
      </ImageContainer>
      <p>
        Forsikringsbeviset gjelder foran forsikringsvilkårene.
        Forsikringsvilkårene gjelder foran generelle vilkår. Forsikringsbeviset,
        forsikringsvilkårene og generelle vilkår gjelder foran lovbestemmelser
        som kan fravikes.
      </p>
    </InsuranceContractContainer>
  );
};

export default InsuranceContract;

InsuranceContract.propTypes = {
  data: PropTypes.shape({
    policyNumber: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      registrationNumber: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const InsuranceContractContainer = styled.div`
  margin-top: 7rem;

  @media print {
    margin-top: 3cm;
  }
`;

const ImageContainer = styled.div`
  display: flex;

  img {
    margin: 2rem auto;
    width: 30rem;
    height: 21.092rem;

    @media print {
      width: 12cm;
      height: 8.43674cm;
      margin: 2cm auto;
    }
  }
`;

const UL = styled.ul``;
