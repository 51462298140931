import PropTypes from 'prop-types';
import styled from 'styled-components';

const PolicyOverview = ({ changePeriod, data }) => {
  const formatDate = (date) => {
    const d = new Date(date);

    return d.toLocaleString('no-NO', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  const showChangePeriod = () =>
    (data?.start ?? data.startDate) === data.activePeriod.start &&
    (data?.end ?? data.endDate) === data.activePeriod.end
      ? false
      : true;

  const getTermsAndConditionVersion = (startDate) => {
    if (startDate >= '2025-02-01') {
      return 'Vilkår Taxi 01.02.2025';
    } else if (startDate >= '2022-03-01') {
      return 'Vilkår Taxi 01.03.2022';
    } else {
      return 'Vilkår Taxi 01.03.2021';
    }
  };

  return (
    <PolicyOverviewContainer>
      <div>
        <Label>Utstedt: </Label>
        {formatDate(data.createdAt)}
      </div>
      <div>
        <Label>Gjeldende vilkår: </Label>
        {getTermsAndConditionVersion(data?.start ?? data.startDate)}
      </div>
      <div>
        <Label>Forsikringsperiode: </Label>
        {formatDate(data?.start ?? data.startDate)} –{' '}
        {formatDate(data?.end ?? data.endDate)}
      </div>
      {changePeriod && showChangePeriod() && (
        <div>
          <Label>Endringsperiode: </Label>
          {formatDate(data.activePeriod.start)} –{' '}
          {formatDate(data.activePeriod.end)}
        </div>
      )}
    </PolicyOverviewContainer>
  );
};

PolicyOverview.propTypes = {
  changePeriod: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    end: PropTypes.string,
    endDate: PropTypes.string,
    start: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
};

export default PolicyOverview;

const PolicyOverviewContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-items: flex-end;
  margin-right: 0;
  line-height: 1.4;

  div {
    margin-left: auto;
  }

  @media print {
    font-size: 10pt;
  }
`;

const Label = styled.span`
  font-weight: 700;
  color: #6b7982;
`;
