const Information = () => {
  return (
    <>
      <p>
        Forsikringen er formidlet av RA Forsikring AS - Taxiforsikring, Org nr
        926 333 771.
        <br />
        RA er registrert og godkjent av Finanstilsynet som
        forsikringsagentforetak.
        <br />
        RA er forsikringsagent for Eir Försäkring AB, som er forsikringsgiver,
        og utsteder derfor forsikringene i kraft av fullmakt fra Eir Försäkring
        AB. RA Forsikring AS mottar 18,25 % provisjon på nysalg og fornyelse.
        <br />
        RA administrerer forsikringsavtalen på vegne av Eir Försäkring AB og
        eventuelle spørsmål knyttet til forsikringsavtalen, dens vilkår og
        betingelser, samt skadebehandling skal rettes til RA Forsikring AS.
      </p>
      <h2>Sikkerhetsforskrifter og ansvarsbegrensninger</h2>
      <p>
        Vær oppmerksom på at forsikringsbeviset henviser til
        sikkerhetsforskrifter som sier hva du selv er forpliktet til å gjøre for
        å unngå eller begrense skader. Dersom disse ikke overholdes kan
        erstatning bli redusert eller falle bort, jf. FAL § 4-8.{' '}
      </p>
      <h2>Husk å melde fra om endringer</h2>
      <p>
        Prisen for forsikringen er beregnet ut fra opplysningene du har gitt
        oss. Husk derfor å melde fra om endringer som kan ha betydning for
        forsikringsavtalen. Hvis det har skjedd endringer vi ikke er gjort kjent
        med og det skjer en skade, kan erstatning bli redusert eller falle bort,
        jf. FAL § 4-6 og § 4-7.
      </p>
      <h2>Meldefrist for skade</h2>
      <p>
        Ved skade må du melde fra til oss uten ugrunnet opphold. Er ikke kravet
        meldt innen ett år etter at du fikk kunnskap om de forhold som begrunner
        det, kan erstatning falle bort, jf. FAL § 8-5.
      </p>
      <p>
        For å melde skade, gå inn på{' '}
        <a
          href='https://www.forsikring.taxi/meld-skade'
          target='_blank'
          rel='noreferrer'
        >
          www.forsikring.taxi
        </a>{' '}
        eller til
      </p>
      <p>
        Taxiforsikring c/o Eir Forsikring
        <br />
        Postboks 1292 Vika
        <br />
        0111 Oslo
        <br />
        <a href='mailto:taxiforsikring@eirforsakring.se'>
          taxiforsikring@eirforsakring.se
        </a>
      </p>
      <h2>Rett til å kreve nemndbehandling</h2>
      <p>
        Dersom det oppstår tvist med selskapet, kan hver av partene kreve
        nemndbehandling etter FAL § 20-1. Finansklagenemda, Postboks 53 Skøyen,
        0212 Oslo – www.finkn.no.
      </p>
      <h2>Klagemuligheter</h2>
      <p>
        Dersom du ikke er fornøyd med vår avgjørelse, kan du sende en klage til
        vår klageservice: klage@forsikring.taxi eller direkte til
        Finansklagenemda www.finkn.no.
      </p>
      <h2>Informasjon om forsikringsgiver</h2>
      <p>
        Eir Försäkring AB, org.nr 559166-0617, Norrlandsgatan 11, 111 43
        Stockholm, Sverige. har konsesjon fra den svenske Finansinspektionen til
        å bedrive forsikringsformidling i henhold til Försäkringsrörelselagen
        (2010:2043) og er godkjent av Finansinspektionen samt Finanstilsynet i
        Norge til å drive grensekryssende virksomhet til Norge, ID: FT00115507.
        Eir Försäkring AB kan kontaktes via adressen over, samt via
        info@eirforsakring.se. Mer informasjon finnes også på
        www.eirforsakring.se.
      </p>
      <h2>Garantiordningen for skadeforsikring</h2>
      <p>
        Da forsikringsgiver ikke er norsk kan selskapet ikke være medlem av den
        norske Garantiordningen. Forsikringsgiver er tilknyttet TFF,
        Finansklagenemda og FOSS i likhet med norske forsikringsselskaper.
      </p>
    </>
  );
};

export default Information;
