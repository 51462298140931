import styled from 'styled-components';

const SafetyRegulationsPart1 = () => {
  return (
    <>
      <p>
        Forsikringen er overtatt på betingelse av at de fastsatte
        sikkerhetsforskrifter til enhver tid overholdes. Har sikrede forsømt å
        overholde sikkerhetsforskriftene, eller påse at de blir overholdt, kan
        Selskapet være helt eller delvis uten ansvar (jf. FAL § 4-8).
      </p>
      <p>
        Endres selskapets risiko for skader i forhold til den avtale som er
        angitt i forsikringsbeviset, plikter forsikringstakeren straks å
        underrette selskapet.
      </p>
      <p>
        <Strong>
          Følgene av å unnlate å gi selskapet beskjed om endringer av risikoen
          som betinger høyere premie enn den som er betalt, er:
        </Strong>
      </p>
      <ol>
        <li>
          Ved kaskoskade begrenses selskapets ansvar til hva som svarer til
          forholdet mellom premie som er betalt, og den som skulle vært betalt.
        </li>
        <li>
          Ved ansvarsskade har selskapet tilsvarende regressrett overfor
          forsikringstakeren, dog begrenset til 5 ganger årspremien for
          ansvarsforsikring.
        </li>
      </ol>
      <p>Punkt 1 omfatter følgende tilfeller:</p>
      <ul>
        <li>
          Endring av bilens ytelse, som gjør at bilen blir lik eller tilnærmet
          lik en bil med høyere modellbetegnelse, eller at den avviker fra
          typegodkjenningens oppgitte effekt.
        </li>
        <li>
          Anvendelse av bilen som strider mot forutsetninger gitt i
          forsikringsbeviset.
        </li>
        <li>
          Er det avtalt pris for at bilen kun kjøres av løyvehaver og/eller
          løyvehavers ektefelle/samboer og bilen benyttes av andre, økes avtalt
          egenandel ved kaskoskade med kr 30 000.-
        </li>
        <li>
          Endring av andre avtalte forutsetninger for reduksjon av
          forsikringspremien. Hvilke forutsetninger som er avtalt, vil fremgå av
          forsikringsbeviset.
        </li>
      </ul>
      <p>
        Dersom risikoen endres, eller forsikringsgjenstanden benyttes på en
        annen måte enn det premieberegningen forutsetter, eller det er gjort
        endringer som betinger høyere premie, har selskapet tatt forbehold om
        ansvarsbegrensning og om nedsettelse av erstatningen, jf. FAL § 4-6 og §
        4-7.
      </p>
      <p>Erstatningen kan reduseres eller falle bort dersom:</p>
      <ul>
        <li>
          De sikringstiltak nevnt i forsikringsbeviset ikke gjennomføres eller
          holdes vedlike.
        </li>
        <li>Bilen tas i bruk på annen måte enn avtalt.</li>
        <li>
          Det foretas endringer av bilens ytelse eller ombygging av bilen.
        </li>
      </ul>
      <h2>Førerkort/førerrett</h2>
      <ul>
        <li>
          – Bilens fører skal ha de førerkort, kjøresedler, bevis, sertifikater
          og attester myndighetene krever for den klasse og brukstype bilen
          tilhører.
        </li>
        <li>
          – Fører over 80 år med førerkort i klasse A, A1, A2, AM, B, B1, BE, S
          og T, skal ha helseattest, jf. Førerkortforskriftene § 6.
        </li>
      </ul>
      <p>
        Den som er registrert eier plikter å påse at fører/bruker av bilen
        overholder ovennevnte regler.
      </p>
      <h2>Vognkort</h2>
      <p>
        Vognkortets Del 2 skal oppbevares adskilt fra bilen og på en slik måte
        at det ikke kan benyttes av uvedkommende. Tap av vognkort skal
        umiddelbart meldes til trafikkstasjonen.
      </p>
      <h2>Øvelseskjøring</h2>
      <p>
        Under øvelseskjøring skal elev og ledsager oppfylle gjeldende
        bestemmelser fastsatt i lov/forskrift om lovlig øvelses kjøring.
      </p>
    </>
  );
};

export default SafetyRegulationsPart1;

const Strong = styled.span`
  font-weight: 700;
`;
