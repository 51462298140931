const SafetyRegulationsPart2 = () => {
  return (
    <>
      <h2>Utrusting, overbelastning og vedlikehold</h2>
      <p>
        Bilen skal være utrustet slik at den er i trafikksikker stand. Gods skal
        sikres forsvarlig. Den skal ikke overbelastes, og behandling og
        vedlikehold av bilen skal skje i henhold til fabrikantens/leverandørens
        anbefalinger og i henhold til gjeldende bestemmelser i lov/forskrift.
        Bilens væskekjølesystem skal være påfylt frosthindrende middel tilpasset
        de klimatiske forhold. Fabrikantens serviceintervaller skal overholdes.
      </p>
      <h2>Utgifter til rettsbehandling</h2>
      <p>
        Sikrede skal ved eventuell dekning av utgifter til sakkyndige som ikke
        er oppnevnt av retten, innhente forhåndsgodkjennelse av selskapet.
      </p>
      <h2>Saksomkostninger ved forlik</h2>
      <p>
        Sikrede skal ved eventuell inngåelse av avtale om saksomkostninger ved
        forlik, innhente forhåndsgodkjennelse av selskapet.
      </p>
      <h2>Nøkler</h2>
      <ul>
        <li>
          Nøkler skal oppbevares adskilt fra bilen når denne forlates, og på en
          slik måte at de ikke kan benyttes av uvedkommende.
        </li>
        <li>
          Eier eller rettmessig bruker skal ha tilsyn med nøkler som ikke er i
          bruk, eller sørge for at disse er innelåst i bygning eller rom i
          bygning.
        </li>
        <li>
          Biler med elektronisk startsperre skal uten unødig opphold få denne
          omprogrammert, dersom nøkler er kommet på avveie.
        </li>
      </ul>
      <h2>Tyverisikring</h2>

      <ul>
        <li>Bilen skal være lukket og låst når det ikke er personer i den.</li>
        <li>
          I forbindelse med salg av bilen skal eieren, eller en han/hun har
          bemyndiget til å bistå med salget, være på/i denne så lenge
          tenningsnøkkel befinner seg der.
        </li>
        <li>
          Deler av bilen som midlertidig er avmontert, skal være innelåst.
        </li>
        <li>
          Ekstra dekk og felger skal være innelåst. I fellesgarasje skal ekstra
          dekk og felger være fastlåst til bygning.
        </li>
        <li>
          Fastmontert utstyr skal være montert slik at det ikke kan fjernes uten
          bruk av verktøy eller nøkkel.
        </li>
        <li>
          Radio-/musikkanlegg skal være fastmontert eller montert i
          tyverikassett.
        </li>
        <li>Skiboks, sykkel- eller skistativ skal være fastmontert og låst.</li>
        <li>
          Om natten, i tiden fra man setter bilen fra seg om kvelden, til man
          tar den i bruk igjen – og i alle tilfeller i tiden mellom klokken
          00.00 og 06.00, skal bagasje ikke befinne seg på bilens tak.
        </li>
        <li>
          Løsøre som etterlates i bil, skal oppbevares i separat, låst
          bagasjerom eller tilsvarende rom uten innsynsmulighet.
        </li>
      </ul>
    </>
  );
};

export default SafetyRegulationsPart2;
