import PropTypes from 'prop-types';
import styled from 'styled-components';

const CoverAndPremiumSummary = ({
  coverLevel,
  premiums,
  period,
  optGlass = 'false',
}) => {
  const Dekning = () => {
    switch (coverLevel) {
      case 'Ansvar':
        return <Label>Ansvar</Label>;
      case 'Delkasko':
        return <Label>Delkasko (inkluderer Ansvar)</Label>;
      case 'Kasko':
        return <Label>Kasko (inkluderer Ansvar, Delkasko)</Label>;
      case 'Super':
        return <Label>Super (inkluderer Ansvar, Delkasko, Kasko)</Label>;
      default:
        return <Label></Label>;
    }
  };

  return (
    <>
      <h2>Dekninger og premie</h2>
      <CoverAndPremiumGrid>
        <Row>
          <CoverAndPremiumGridCaption>Dekning</CoverAndPremiumGridCaption>
          <CoverAndPremiumGridCaption>
            Premie denne perioden
          </CoverAndPremiumGridCaption>
          <CoverAndPremiumGridCaption>Årspremie</CoverAndPremiumGridCaption>
        </Row>
        <div>
          <ItemRow>
            <Dekning />
            <AmountItem>
              Kr{' '}
              {period
                ? (
                    premiums.dayPremiumExcludingTax * period.duration
                  ).toLocaleString('no-NO', {
                    maximumFractionDigits: 0,
                  })
                : premiums.annualizedPremiumExcludingTax.toLocaleString(
                    'no-NO',
                    {
                      maximumFractionDigits: 0,
                    }
                  )}
            </AmountItem>
            <AmountItem>
              Kr{' '}
              {premiums.annualizedPremiumExcludingTax.toLocaleString('no-NO', {
                maximumFractionDigits: 0,
              })}
            </AmountItem>
          </ItemRow>
          <ItemRow>
            <Label>
              {optGlass === 'true'
                ? 'Glassdekning er inkludert'
                : 'Glassdekning er ikke inkludert'}
            </Label>
            <div></div>
            <div></div>
          </ItemRow>
          <ItemRow>
            <Label>Trafikkforsikringsavgift</Label>
            <AmountItem>
              Kr{' '}
              {period
                ? (premiums.dayTax * period.duration).toLocaleString('no-NO', {
                    maximumFractionDigits: 0,
                  })
                : premiums.annualizedTax.toLocaleString('no-NO', {
                    maximumFractionDigits: 0,
                  })}
            </AmountItem>
            <AmountItem>
              Kr{' '}
              {premiums.annualizedTax.toLocaleString('no-NO', {
                maximumFractionDigits: 0,
              })}
            </AmountItem>
          </ItemRow>
          <SummaryRow>
            <Label>Totalt denne periode</Label>
            <AmountItem>
              Kr{' '}
              {period
                ? (
                    (premiums.dayPremiumExcludingTax + premiums.dayTax) *
                    period.duration
                  ).toLocaleString('no-NO', {
                    maximumFractionDigits: 0,
                  })
                : (
                    premiums.annualizedPremiumExcludingTax +
                    premiums.annualizedTax
                  ).toLocaleString('no-NO', {
                    maximumFractionDigits: 0,
                  })}
            </AmountItem>
            <AmountItem>
              Kr{' '}
              {(
                premiums.annualizedPremiumExcludingTax + premiums.annualizedTax
              ).toLocaleString('no-NO', {
                maximumFractionDigits: 0,
              })}
            </AmountItem>
          </SummaryRow>
        </div>
      </CoverAndPremiumGrid>
    </>
  );
};

CoverAndPremiumSummary.propTypes = {
  coverLevel: PropTypes.string.isRequired,
  period: PropTypes.shape({
    duration: PropTypes.number.isRequired,
  }),
  premiums: PropTypes.shape({
    annualizedPremiumExcludingTax: PropTypes.number.isRequired,
    annualizedTax: PropTypes.number.isRequired,
  }).isRequired,
};

export default CoverAndPremiumSummary;

const CoverAndPremiumGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fafafa;
  border-radius: 0.625rem;
  padding: 1rem;

  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    padding: 5pt;
    font-size: 8pt;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  padding: 0.25rem 0.5rem;
  border-bottom: 0.0625rem solid #eaedee;

  @media print {
    padding: 5pt;
  }
`;

const CoverAndPremiumGridCaption = styled.div`
  color: #384954;
  font-size: 1.2rem;
  text-align: right;

  &:first-child {
    text-align: left;
  }

  @media print {
    font-size: 12pt;
  }
`;

const ItemRow = styled(Row)`
  &:last-child {
    border-top: 0.0625rem dotted #eaedee;
  }
`;

const SummaryRow = styled(Row)`
  background-color: #f3f1f2;
  font-size: 1.2rem;
  font-weight: 600;

  @media print {
    font-size: 10pt;
  }
`;

const AmountItem = styled.div`
  text-align: right;
`;

const Label = styled.span`
  font-weight: 700;
  color: #6b7982;
`;
